import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Button, Upload } from 'antd';
import { InboxOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import GridTable from 'src/components/GridTable';
import CommentInput from 'src/components/CommentInput';
import CancelInput from 'src/components/CancelInput';
import Cancel from 'src/components/Cancel';
import Comment from 'src/components/Comment';
import ApplicationHeaderBox from 'src/components/ApplicationHeaderBox';
import {
  Router,
  TaiwanCountyTxt,
  ApplicationStatus,
  CommentType
} from 'src/constants';
import { parseDate, customUploadRequest } from 'src/utils';
import Loading from 'src/components/Loading';
import NoPermission from 'src/components/NoPermission';
import VM from './viewModel';
import styles from './styles.module.scss';

@observer
class FosterItemApplicationPage extends React.Component {
  constructor(porps) {
    super(porps);

    const fosterId = this.props.router.params.fosterId;
    const applicationId = this.props.router.params.applicationId;

    this.vm = new VM({
      fosterId,
      applicationId,
      profile: this.props.profile
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderDesignationModal = () => {
    return (
      <div className={styles.designation_modal}>
        <div>若進行到此步驟，表示您有高度意願將貓咪出養給該認養人，貓咪狀態會顯示為【簽約中】，即不再接收新的認養申請。</div>
        <div>送出後不可收回，確認要繼續嗎？</div>
      </div>
    );
  };

  render() {
    if (this.vm.isPageLoading || !this.vm.hasPermission) {
      return (
        <>
          {this.vm.isPageLoading && <Loading />}
          {!this.vm.isPageLoading && <NoPermission callback={() => this.props.router.navigate(Router.Client.Home)} />}
        </>
      );
    }


    const foster = this.vm.foster;
    const application = this.vm.application;
    const status = application?.status;
    const applicant = this.vm.applicant;
    const createdAt = applicant ? dayjs().diff(dayjs(applicant.createdAt), 'M') : '';
    const applicationMatched = applicant?.application.matched ?? '';
    const applicationOngoing = applicant?.application.ongoing ?? '';
    const applicationCanceled = applicant?.application.canceled ?? '';

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '出養管理', link: Router.Client.FosterList },
            { title: '出養案件管理', link: `${Router.Client.FosterList}/${this.vm.fosterId}` },
            { title: `${foster?.name ?? ''}認養申請詳細頁` }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>

            <div className={styles.leftCol}>
              <div className={styles.imgBox}>
                <img className={styles.img} src={foster?.images?.[0]} alt="cat" />
              </div>
              <div className={styles.name}>{foster?.name}</div>
            </div>

            <div className={styles.rightCol}>

              <ApplicationHeaderBox
                fosterId={application?.fosterId}
                person={applicant}
                namePrefix="申請人"
                msgText="聯繫申請人"
                status={status}
                infos={[
                  (
                    <span>
                      使用本網站：
                      <span className={styles.stats}>{ createdAt }</span>
                      個月
                    </span>
                  ),
                  (
                    <span>
                      媒合成功貓咪：
                      <span className={styles.stats}>{ applicationMatched }</span>
                      隻
                    </span>
                  ),
                  (
                    <span>
                      進行中認養申請：
                      <span className={styles.stats}>{ applicationOngoing }</span>
                      筆
                    </span>
                  ),
                  (
                    <span>
                      媒合取消貓咪：
                      <span className={styles.stats}>{ applicationCanceled }</span>
                      隻
                    </span>
                  )
                ]}
              />

              <GridTable
                shortList={[
                  { key: '姓名／組織名稱', value: applicant?.name ?? '-' },
                  { key: '手機', value: applicant?.phone ?? '-' },
                  { key: '備用聯絡電話', value: applicant?.contactNumber ?? '-' },
                  { key: '地區', value: TaiwanCountyTxt[applicant?.region] ?? '-' }
                ]}
                longList={[
                  { key: '提交申請時間', value: parseDate(application?.submitAt, '-') },
                  { key: 'Email', value: applicant?.email ?? '-' },
                  { key: '申請人的留言', value: application?.message ?? '-' },
                  { key: '下載簽約檔案',
                    value: (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        {application?.contracts.map((contract) => {
                          const fileName = contract.split(`${this.vm.applicationId}/`)[1];
                          return (
                            <div className={styles.contract_item} key={contract}>
                              <Button
                                type="link"
                                href={contract}
                                target="_blank"
                                className={styles.downloadBtn}
                              >
                                <PaperClipOutlined />
                                <span style={{ textAlign: 'left' }}>{fileName}</span>
                              </Button>
                              {this.vm.canDeleteContract && (
                              <DeleteOutlined
                                className={styles.deleteBtn}
                                onClick={() => this.vm.deleteContract(fileName)}
                              />
                              )}
                            </div>
                          );
                        }) ?? '-'}
                      </div>
                    )
                  }
                ]}
              />

              {/* 媒合成功-評價出養人 */}
              {this.vm.isMatchedCommentVisible && (
              <Comment
                userId={applicant?.id}
                commentPairId={this.vm.commentPairId}
                comment={this.vm.matchedComment}
                title="媒合成功-評價認養人"
              />
              )}
              {this.vm.canCreateMatchedComment && (
              <div className={styles.commentInputBox}>
                <CommentInput
                  title="媒合成功-評價認養人"
                  onClick={(props) => this.vm.createComment(props, CommentType.MatchedComment)}
                />
              </div>
              )}
              {/* 取消出養原因 */}
              {(application?.cancelOption || application?.cancelDescription) && (
              <Cancel
                title={this.vm.text}
                cancelOption={application?.cancelOption ?? ''}
                cancelDescription={application?.cancelDescription ?? ''}
              />
              )}
              {/* 媒合取消-評價出養人 */}
              {this.vm.isCanceledVisible && (
              <Comment
                userId={applicant?.id}
                commentPairId={this.vm.commentPairId}
                comment={this.vm.canceledComment}
                title={`${this.vm.text}-評價認養人`}
              />
              )}
              {this.vm.canCreateCanceledComment && (
              <div className={styles.commentInputBox}>
                <CommentInput
                  title={`${this.vm.text}-評價出養人`}
                  onClick={(props) => this.vm.createComment(props, CommentType.CanceledComment)}
                />
              </div>
              )}

              {/* 已申請 或 討論中 */}
              {(status === ApplicationStatus.Applied || status === ApplicationStatus.InDiscussion) && (
              <>
                <div className={styles.h1}>回覆申請人</div>
                <div className={styles.row}>
                  {status === ApplicationStatus.Applied && (<Button className={styles.btn} onClick={this.vm.contact}>有意願進行聯繫，請保持聯絡</Button>)}
                  <Button className={styles.btn} onClick={() => this.vm.designation(this.renderDesignationModal)}>已完成約訪，準備簽約</Button>
                  <Button className={styles.btn} onClick={this.vm.declineApplication}>婉拒申請</Button>
                </div>
              </>
              )}

              {/* 簽約中 */}
              {(status === ApplicationStatus.InContract) && (
                <>
                  <div className={styles.h2}>請確認此份合約為完整檔案，有出養方和認養方雙方的簽名和資料。再送出檔案給認養方確定認養唷！</div>
                  <div className={styles.h2}>上傳簽約檔案（上限10個檔案，支援 .pdf, .jpg, .png）：</div>
                  <div style={{ height: 200, marginBottom: 50 }}>
                    <Upload.Dragger
                      customRequest={customUploadRequest}
                      accept="image/png,image/jpg,image/jpeg,.pdf"
                      onChange={this.vm.uploadContract}
                      showUploadList={false}
                      multiple
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">點擊此處或將檔案拖放至此</p>
                    </Upload.Dragger>
                  </div>
                  <div className={styles.h1}>回覆申請人</div>
                  <div className={styles.row}>
                    <Button className={styles.btn} onClick={this.vm.confirmApplication}>確認出養，請核對合約文件</Button>
                    <Button className={styles.btn} onClick={this.vm.declineApplication}>婉拒申請</Button>
                  </div>
                </>
              )}

              {/* 核對中 */}
              {(status === ApplicationStatus.UnderReview) && (
              <div>
                <Button className={styles.btn} onClick={this.vm.declineApplication}>婉拒申請</Button>
              </div>
              )}

              {/* 媒合成功 */}
              {(status === ApplicationStatus.Matched) && (
              <>
                {!this.vm.showCancelInput && (
                <Button
                  className={cn([styles.btn, styles.bgGray])}
                  onClick={this.vm.OnShowCancelInputChange}
                >
                  取消出養
                </Button>
                )}
                {this.vm.showCancelInput && (
                <CancelInput type="cancelFoster" onClick={this.vm.cancelFoster} />
                )}
              </>
              )}

              {/* 取消認養確認 */}
              {(status === ApplicationStatus.CancelAdoptionConfirmation) && (
              <div className={styles.row2}>
                <Button className={styles.btn} onClick={this.vm.confirmCancelAdoption}>同意</Button>
                <Button className={cn([styles.btn, styles.bgGray])} onClick={this.vm.refuseCancelAdoption}>拒絕</Button>
              </div>
              )}

              <div className={styles.text}>{`狀態變更時間：${parseDate(application?.statusUpdateAt, '-')}`}</div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

FosterItemApplicationPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

export default withRouter(withProfile(FosterItemApplicationPage, true));
