/**
 * ! Note: 未來若又增加新版合約，需修改 default 版本
 */
export function getVersion(foster) {
  try {
    const { contractVersion } = foster?.lastMatchedApplication || {};

    /**
     * backward compatible 1.0
     *
     * with last matched application, but (no version or version is 1.0)
     */
    if (foster?.lastMatchedApplication && (!contractVersion || contractVersion === '1.0')) {
      return '1.0';
    }

    return contractVersion ?? '2.0';
  } catch (err) {
    return '2.0';
  }
}
