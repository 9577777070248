import { message } from 'antd';
import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import FosterService from 'src/services/foster';
import CompletenessService from 'src/services/completeness';
import { Router, FosterStatus } from 'src/constants';
import infoStore from 'src/stores/infoStore';

export default class FosterItemVM {
  fosterId = null;
  profile = null;
  form = null; // antd form instance
  fosterImages = []; // updated fileList
  bloodTestImg = []; // updated fileList
  fosterAvatar = undefined; // updated file
  tabKey = {
    info: 'info',
    contract: 'contract',
    applications: 'applications'
  };

  @observable defaultFosterImages = null; // current img in db
  @observable defaultFosterAvatar = null; // current img in db
  @observable defaultBloodTestImg = null; // current img in db
  @observable foster = null;
  @observable isPageLoading = false;
  @observable hasPermission = false;

  @observable isSaving = false;
  @observable isFosterFreeze = false;
  @observable activeTab = 'info';
  @observable showBloodTest = false;
  @observable showReport = false;
  @observable showNeuteringDeadLine = false;
  @observable showImgError = false;
  @observable showBloodTestImgError = false;
  @observable completeness = {
    partOne: 0,
    partTwo: 0,
    partThree: 0,
    all: 0
  };

  @observable application = {
    list: [],
    anchor: null,
    limit: 40,
    isLoading: false
  };

  constructor(profile) {
    makeObservable(this);
    this.profile = profile;
  }

  @computed get isDisabled() {
    return this.isFosterFreeze || this.foster?.status === FosterStatus.Ended || this.foster?.status === FosterStatus.Adopted;
  }

  @computed get isUnavailable() {
    return this.foster?.status === FosterStatus.Unavailable ?? false;
  }

  @computed get isEnd() {
    return this.foster?.status === FosterStatus.Ended || this.foster?.status === FosterStatus.Adopted;
  }

  didMount = async ({ fosterId, form }) => {
    this.fosterId = fosterId;
    this.form = form;
    runInAction(() => { this.isPageLoading = true; });

    if (this.fosterId) {
      try {

        const foster = await FosterService.getFosterById(this.fosterId);

        // check user permission
        if (this.profile.isAdmin || this.profile.id === foster.fosterPerson.id) {
          runInAction(() => { this.hasPermission = true; });
        } else {
          runInAction(() => { this.isPageLoading = false; });
          return;
        }

        const isFosterFreeze = await FosterService.isFosterFreeze(this.fosterId);

        this.form.setFieldsValue({
          ...foster,
          birthday: foster.birthday ? dayjs(foster.birthday) : undefined,
          neuteringDeadLine: foster.neuteringDeadLine ? dayjs(foster.neuteringDeadLine) : undefined
        });

        const completeness = CompletenessService.getCompleteness(foster);

        runInAction(() => {
          this.activeTab = foster.status === FosterStatus.Unavailable
            ? this.tabKey.info
            : this.tabKey.applications;
          this.foster = foster;
          this.isFosterFreeze = isFosterFreeze;
          this.completeness = completeness;
          this.showBloodTest = foster.hasBloodTest;
          this.showReport = foster.needReport;
          this.showNeuteringDeadLine = foster.needNeutering;
          this.defaultFosterImages = foster.images?.map((image, index) => ({
            uid: `-${index}`,
            name: 'image.png',
            status: 'done',
            url: image
          })) ?? [];
          this.defaultBloodTestImg = foster.bloodTestImg?.map((image, index) => ({
            uid: `-${index}`,
            name: 'bloodTest.png',
            status: 'done',
            url: image
          })) ?? [];
          this.defaultFosterAvatar = foster.avatar ? [
            {
              uid: 'avatar',
              name: 'avatar.png',
              status: 'done',
              url: foster.avatar
            }
          ] : [];
          this.fosterImages = [...this.defaultFosterImages];
          this.bloodTestImg = [...this.defaultBloodTestImg];
          this.isPageLoading = false;
        });

        this.getApplicationList();

      } catch {
        runInAction(() => { this.isPageLoading = false; });
        infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
      }

    } else {
      try {
        const lastestFoster = await FosterService.getMyLatestFoster();
        if (lastestFoster) {
          const needReport = lastestFoster.needReport;
          this.form.setFieldsValue({
            needReport,
            observationDuration: needReport ? lastestFoster.observationDuration : undefined,
            observationFrequency: needReport ? lastestFoster.observationFrequency : undefined,
            stabilityFrequency: needReport ? lastestFoster.stabilityFrequency : undefined,
            canRehome: lastestFoster.canRehome,
            deathOrLossNotification: lastestFoster.deathOrLossNotification,
            deathOrLossUnlimitedLiability: lastestFoster.deathOrLossUnlimitedLiability,
            trialPeriod: lastestFoster.trialPeriod,
            needNeutering: lastestFoster.needNeutering,
            needHomeVisit: lastestFoster.needHomeVisit,
            needWindowSecurity: lastestFoster.needWindowSecurity,
            others: lastestFoster.others
          });
        }
        runInAction(() => {
          this.showNeuteringDeadLine = lastestFoster?.needNeutering ?? false;
          this.showReport = lastestFoster?.needReport ?? false;
        });
      } catch {
        // ignore
      }

      runInAction(() => {
        this.hasPermission = true;
        this.isPageLoading = false;
        this.defaultFosterImages = [];
        this.defaultFosterAvatar = [];
        this.defaultBloodTestImg = [];
      });
    }
  };

  refresh = async () => {
    const foster = await FosterService.getFosterById(this.fosterId);

    runInAction(() => {
      this.foster = foster;
      this.showBloodTest = foster.hasBloodTest;
      this.showReport = foster.needReport;
      this.showNeuteringDeadLine = foster.needNeutering;
      this.fosterAvatar = undefined;
      this.defaultFosterImages = foster.images?.map((image, index) => ({
        uid: `-${index}`,
        name: 'image.png',
        status: 'done',
        url: image
      })) ?? [];
      this.defaultBloodTestImg = foster.bloodTestImg?.map((image, index) => ({
        uid: `-${index}`,
        name: 'bloodTest.png',
        status: 'done',
        url: image
      })) ?? [];
      this.defaultFosterAvatar = foster.avatar ? [
        {
          uid: 'avatar',
          name: 'avatar.png',
          status: 'done',
          url: foster.avatar
        }
      ] : [];
      this.fosterImages = [...this.defaultFosterImages];
      this.bloodTestImg = [...this.defaultBloodTestImg];
    });
  };

  getApplicationList = async () => {
    const { list, anchor } = await FosterService.getApplicationByFoster(
      this.fosterId,
      { limit: this.application.limit }
    );
    runInAction(() => {
      this.application.list = list;
      this.application.anchor = anchor;
    });
  };

  onApplicationNextPage = async () => {
    if (this.application.isLoading || !this.application.anchor) return;

    runInAction(() => { this.application.isLoading = true; });

    try {
      const { list, anchor } = await FosterService.getApplicationByFoster(
        this.fosterId,
        { limit: this.application.limit, anchor: this.application.anchor }
      );
      runInAction(() => {
        this.application.list = this.application.list.concat(list);
        this.application.anchor = anchor;
        this.application.isLoading = false;
      });

    } catch {
      runInAction(() => { this.application.isLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得認養申請資訊', type: 'warn' });
    }
  };

  validateForm = async () => {
    try {
      const ImgHasError = this.fosterImages.length === 0;
      const BloodTestImgHasError = this.form.getFieldValue('hasBloodTest') && this.bloodTestImg.length === 0;

      runInAction(() => {
        this.showImgError = ImgHasError;
        this.showBloodTestImgError = BloodTestImgHasError;
      });

      await this.form.validateFields();
      if (ImgHasError || BloodTestImgHasError) throw new Error();

    } catch {
      throw new Error('formError');
    }
  };

  isFormEmpty = () => {
    const formValue = this.form.getFieldsValue();
    const isEmpty = Object.values(formValue).every((value) => {
      if (typeof value === 'string') {
        return value.trim() === '';
      }
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === undefined;
    });
    return isEmpty;
  };

  updateFoster = async (showMessage = true) => {
    try {
      const diff = (arrayA, arrayB) => {
        const result = [];
        arrayA.forEach((A) => {
          const isInArrayB = arrayB.find((B) => A.uid === B.uid);
          if (!isInArrayB) result.push(A);
        });
        return result;
      };

      const deletedFosterImg = diff(this.defaultFosterImages, this.fosterImages).map((f) => f.url);
      const newFosterImg = diff(this.fosterImages, this.defaultFosterImages).map((f) => f.originFileObj);

      const deletedBolldTestImg = diff(this.defaultBloodTestImg, this.bloodTestImg).map((f) => f.url);
      const newBloodTestImg = diff(this.bloodTestImg, this.defaultBloodTestImg).map((f) => f.originFileObj);

      const formValue = this.form.getFieldsValue();
      await FosterService.updateFoster(this.fosterId, {
        ...formValue,
        images: newFosterImg,
        bloodTestImg: newBloodTestImg,
        avatar: this.fosterAvatar,
        deletedImages: deletedFosterImg,
        deletedBloodTest: deletedBolldTestImg
      });

      if (showMessage) {
        message.open({
          type: 'success',
          content: this.foster.status === FosterStatus.Unavailable
            ? '資料已儲存更新。請確認要將貓咪狀態改為「上架」，才是正式刊登唷！'
            : '資料已儲存更新！'
        });
      }

    } catch (err) {
      switch (err?.response?.status) {
        case 409:
          infoStore.show({ message: '有認養申請核對中，無法更新', type: 'warn' });
          break;
        default:
          throw new Error('updateError');
      }
    }
  };

  createFoster = async () => {
    try {
      const newImages = this.fosterImages.map((file) => file.originFileObj).filter((file) => !!file);
      const newBloodTestImg = this.bloodTestImg.map((file) => file.originFileObj).filter((file) => !!file);
      const formValue = this.form.getFieldsValue();

      const foster = await FosterService.createFoster({
        ...formValue,
        images: newImages,
        bloodTestImg: newBloodTestImg,
        avatar: this.fosterAvatar
      });

      this.fosterId = foster._id;

      window.history.replaceState(null, null, `${Router.Client.FosterList}/${this.fosterId}`);
      message.open({ type: 'success', content: '出養案件管理已建立' });


    } catch {
      throw new Error('createError');
    }
  };


  updateFosterStatus = async ({ key }) => {
    try {

      if (key === FosterStatus.Available) {
        await this.validateForm();
      }

      if (!this.fosterId) {
        const isEmpty = this.isFormEmpty();
        if (isEmpty) return;
        await this.createFoster();

      } else if (!this.isFosterFreeze) {
        await this.updateFoster(false);
      }

      await FosterService.updateFosterStatus(this.fosterId, key);

      if (key === FosterStatus.Available) {
        message.open({ type: 'success', content: '貓咪出養資料已刊登！認養申請會透過簡訊通知，請留意相關訊息唷。' });
      } else if (key === FosterStatus.Unavailable) {
        message.open({ type: 'success', content: '您確定要將這隻貓咪下架嗎？若確認下架，將無法回覆之前尚未回覆的認養申請訊息唷！' });
      }

      this.refresh();

    } catch (e) {
      switch (e.message) {
        case 'formError':
          message.open({ type: 'error', content: '必填欄位未填' });
          break;
        case 'updateError':
          infoStore.show({ type: 'warn', message: '發生錯誤，無法更新' });
          break;
        case 'createError':
          infoStore.show({ type: 'warn', message: '發生錯誤，無法新增' });
          break;
        default:
          infoStore.show({ type: 'warn', message: '發生錯誤，無法更新狀態' });
      }
    }
  };

  onSave = async () => {
    try {

      runInAction(() => { this.isSaving = true; });

      if (this.fosterId) {
        await this.updateFoster();

      } else {
        const isEmpty = this.isFormEmpty();
        if (isEmpty) return;
        await this.createFoster();
      }

      this.refresh();

    } catch (e) {
      switch (e.message) {
        case 'formError':
          message.open({ type: 'error', content: '必填欄位未填' });
          break;
        case 'updateError':
          infoStore.show({ type: 'warn', message: '發生錯誤，無法更新' });
          break;
        case 'createError':
          infoStore.show({ type: 'warn', message: '發生錯誤，無法新增' });
          break;
        default:
      }
    } finally {
      runInAction(() => { this.isSaving = false; });
    }
  };

  readApplication = async (applicationId, userId) => {
    if (userId === this.foster.fosterPerson.id) {
      await FosterService.readApplication(this.fosterId, applicationId);
    }
  };

  @action onFormChange = (changedValues, formValue) => {
    // toggle ui
    const key = Object.keys(changedValues)[0];
    const value = changedValues[key];

    switch (key) {
      case 'hasBloodTest':
        this.showBloodTest = value;
        break;
      case 'needReport':
        this.showReport = value;
        break;
      case 'needNeutering':
        this.showNeuteringDeadLine = value;
        break;
      default:
    }

    // calculate completeness
    const completeness = CompletenessService.getCompleteness({ ...formValue, images: this.fosterImages });
    this.completeness = completeness;
  };
  @action OnImagesUpload = ({ file, fileList }) => {
    if (fileList?.length === 0) {
      this.showImgError = true;
    } else {
      this.showImgError = false;
    }
    const formValue = this.form.getFieldsValue();
    const completeness = CompletenessService.getCompleteness({ ...formValue, images: fileList });
    this.fosterImages = fileList;
    this.completeness = completeness;
  };
  @action onTabChange = (e) => {
    this.activeTab = e;
  };
  @action OnBloodTestImgUpload = ({ file, fileList }) => {
    if (fileList?.length === 0) {
      this.showBloodTestImgError = true;
    } else {
      this.showBloodTestImgError = false;
    }
    this.bloodTestImg = fileList;
  };
  @action OnAvatarUpload = ({ file }) => {
    this.fosterAvatar = file.originFileObj;
  };
}
