import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Font, Document } from '@react-pdf/renderer';
import UserService from 'src/services/user';
import { getVersion } from 'src/utils/contract';

import V1 from './V1';
import V2 from './V2';

import fontR from './NotoSansTC-Regular.ttf';
import fontB from './NotoSansTC-Bold.ttf';


Font.register({
  family: 'NotoSansTC',
  fonts: [
    { src: fontR, fontWeight: 400 },
    { src: fontB, fontWeight: 700 }
  ]
});

Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, '']));


// Create Document Component
const ContractPDF = (props) => {
  const { foster, profile } = props;
  const [fosterPerson, setFosterPerson] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (foster
        && profile
        && profile.id === foster.fosterPerson.id
      ) {
        const _fosterPerson = await UserService.getUserById(foster.fosterPerson.id, true);
        setFosterPerson(_fosterPerson);
      }
    }

    fetchData();
  }, [foster, profile]);

  switch (getVersion(foster)) {
    case '1.0':
      return (<V1 foster={foster} fosterPerson={fosterPerson} />);

    default:
      // default latest
      return (<V2 foster={foster} fosterPerson={fosterPerson} />);
  }
};

ContractPDF.propTypes = {
  foster: PropTypes.object,
  profile: PropTypes.object
};

export default ContractPDF;
